<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ติดตามงานแจ้งเตือนภายใน</div>
      <div style="width: 400px">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา ชื่อเรื่อง"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getData"
          item-key="_id"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="success" outlined small>เปิดใช้งาน</v-btn>
      <v-btn color="error" outlined small class="ml-2">ปิดใช้งาน</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined small class="ml-2">ลบ</v-btn>
      <v-btn
        color="primary"
        outlined
        small
        class="ml-5"
        @click="(alert_id = 0), (dialogUpdate = true)"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มการแจ้งเตือน</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @page-count="pageCount = $event"
      show-select
      item-key="_id"
      v-model="selected"
    >
      <template v-slot:[`item.create_date`]="{ item }">
        {{ item.create_date | moment($formatDate) }}
      </template>
      <template v-slot:[`item.alert_time_date`]="{ item }">
        {{ item.alert_time | moment($formatDate) }}
      </template>
      <template v-slot:[`item.alert_time`]="{ item }">
        {{ item.alert_time | moment($formatTime) }}
      </template>
      <template v-slot:[`item.alert_repeat_trigger`]="{ item }">
        {{ item.alert_repeat_trigger == 0 ? 'ไม่' : '' }}
        {{ item.alert_repeat_trigger == 1 ? 'ทุกวัน' : '' }}
        {{ item.alert_repeat_trigger == 2 ? 'ทุกสัปดาห์' : '' }}
        {{ item.alert_repeat_trigger == 3 ? 'ทุกเดือน' : '' }}
        {{ item.alert_repeat_trigger == 4 ? 'ทุกปี' : '' }}
      </template>
      <!-- <template v-slot:[`item.is_available`]="{ item }">
        <menu-ship
          v-model="item.is_available"
          path="sub_category/changed_sub_category_status"
          :params="{sub_category_id: item._id}"
          @success="$alertSuccess(), getData()"
        />
      </template> -->

      <!-- <template v-slot:[`item.count`]="{ item }">
        {{item.count | numeral('0,0')}}
      </template> -->
      <template v-slot:[`item.is_available`]="{ item }">
        <menu-ship
          v-model="item.is_available"
          path="internal/changed_internal_alert_status"
          :params="{ internal_alert_id: item._id }"
          @success="$alertSuccess(), getData()"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item
                dense
                link
                @click="(alert_id = item._id), (dialogUpdate = true)"
              >
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="800" v-model="dialogUpdate">
      <Detail
        :id="alert_id"
        @onClose="dialogUpdate = false"
        @onSave="
          () => {
            dialogUpdate = false;
            getData();
          }
        "
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="800" v-model="dialogAlert">
      <Alert :item="dialogAlertData" @onClose="dialogAlert = false" />
    </v-dialog>
  </div>
</template>

<script>
import Alert from '@/views/pages/follow/alert/Alert.vue';
import Detail from '@/views/pages/follow/alert/Detail.vue';

export default {
  components: {
    Alert,
    Detail
  },
  data: () => ({
    page: 1,
    search: '',
    itemsPerPage: 10,
    selected: [],
    loading: true,
    pageCount: 0,
    internal_alert_id: '',
    list: [],
    headers: [
      { text: 'วันที่สร้าง', value: 'create_date' },
      { text: 'ชื่อเรื่อง', value: 'topic', filterable: false },
      { text: 'รายละเอียด', value: 'detail', filterable: false },
      { text: 'วันที่เตือน', value: 'alert_time_date', filterable: false },
      { text: 'เวลาที่เตือน', value: 'alert_time', filterable: false },
      { text: 'เตือนซ้ำ', value: 'alert_repeat_trigger', filterable: false },
      { text: 'ผู้สร้าง', value: 'created_user', filterable: false },
      { text: 'ผู้รับผิดชอบ', value: 'responsibility_user', filterable: false },
      { text: 'สถานะ', value: 'is_available', filterable: false },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
      branch_id: null
    },

    dialogAlert: false,
    dialogAlertData: '',

    dialogDetail: false,
    dialogDetailUpdate: true,
    dialogUpdate: false,
    alert_id: ''
  }),

  created() {
    this.loading = true;
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },

  methods: {
    // add() {
    //   this.dialogUpdate= true;
    // },

    async remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { internal_alert_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              )
            };

            await this.$axios
              .post(`${this.$baseUrl}/internal/delete_alert`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/internal/get_alert`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },

    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    }
  }
};
</script>
