var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-toolbar',{staticClass:"mb-10",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("ติดตามงานแจ้งเตือนภายใน")]),_c('div',{staticStyle:{"width":"400px"}},[_c('v-text-field',{staticClass:"ml-10",attrs:{"prepend-inner-icon":"mdi-magnify","label":"ค้นหา ชื่อเรื่อง","outlined":"","rounded":"","dense":"","single-line":"","hide-details":"","item-key":"_id"},on:{"keyup":_vm.getData},model:{value:(_vm.filter.start_with),callback:function ($$v) {_vm.$set(_vm.filter, "start_with", $$v)},expression:"filter.start_with"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","outlined":"","small":""}},[_vm._v("เปิดใช้งาน")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","outlined":"","small":""}},[_vm._v("ปิดใช้งาน")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","outlined":"","small":""}},[_vm._v("ลบ")]),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){(_vm.alert_id = 0), (_vm.dialogUpdate = true)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" เพิ่มการแจ้งเตือน")],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"sort-by":"calories","loading-text":_vm.$table_loading_text,"page":_vm.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","show-select":"","item-key":"_id"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.create_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.create_date,_vm.$formatDate))+" ")]}},{key:"item.alert_time_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.alert_time,_vm.$formatDate))+" ")]}},{key:"item.alert_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.alert_time,_vm.$formatTime))+" ")]}},{key:"item.alert_repeat_trigger",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.alert_repeat_trigger == 0 ? 'ไม่' : '')+" "+_vm._s(item.alert_repeat_trigger == 1 ? 'ทุกวัน' : '')+" "+_vm._s(item.alert_repeat_trigger == 2 ? 'ทุกสัปดาห์' : '')+" "+_vm._s(item.alert_repeat_trigger == 3 ? 'ทุกเดือน' : '')+" "+_vm._s(item.alert_repeat_trigger == 4 ? 'ทุกปี' : '')+" ")]}},{key:"item.is_available",fn:function(ref){
var item = ref.item;
return [_c('menu-ship',{attrs:{"path":"internal/changed_internal_alert_status","params":{ internal_alert_id: item._id }},on:{"success":function($event){_vm.$alertSuccess(), _vm.getData()}},model:{value:(item.is_available),callback:function ($$v) {_vm.$set(item, "is_available", $$v)},expression:"item.is_available"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){(_vm.alert_id = item._id), (_vm.dialogUpdate = true)}}},[_c('v-list-item-title',[_vm._v("แก้ไข")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("ลบ")])],1)]],2)],1)]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"800"},model:{value:(_vm.dialogUpdate),callback:function ($$v) {_vm.dialogUpdate=$$v},expression:"dialogUpdate"}},[_c('Detail',{attrs:{"id":_vm.alert_id},on:{"onClose":function($event){_vm.dialogUpdate = false},"onSave":function () {
          _vm.dialogUpdate = false;
          _vm.getData();
        }}})],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"800"},model:{value:(_vm.dialogAlert),callback:function ($$v) {_vm.dialogAlert=$$v},expression:"dialogAlert"}},[_c('Alert',{attrs:{"item":_vm.dialogAlertData},on:{"onClose":function($event){_vm.dialogAlert = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }